<template>
  <div class="questionnaire-page">
    <OwnFeedbackModal
      :message="errorMessage"
      :showing-modal="isOpenOwnFeedBackErrorModal"
      @redirect-to-login="redirectToLogin"
      @show-modal="closeWarningModalModal($event)"/>
    <transition name="circle"
                @after-leave="afterLeave"
                @before-leave="afterEnter">
        <CheckPointScreen
          class="animation-checkpoint-page"
          v-if="showCheckPointPage && completedResult"
        @next-question="nextQuestionEvent"
        :time="60"
        :titleName.sync="titleName"
        :imageCheckPointScreen.sync="imageCheckPointScreen"
        :nameCheckPointScreen.sync="nameCheckPointScreen"
        :text-under-image.sync="textUnderImage"/>
    </transition>
    <div class="question-content">
      <Content class="question">
        <Question
          v-if="currentQuestion"
          :on-boarding-questionnaire="onBoardingQuestionnaire"
          :thanks-u2-page="thanksU2Page"
          @completed-typing="completedTyping($event)"
          :question="onBoardingQuestionnaire ? onBoardingQuestion : currentQuestion.text"/>
        <div v-if="!thanksU2Page && currentQuestion" class="question__select-result">
          <div class="pt-8">
            <QuestionSlider
              :is-explain-page="onBoardingQuestionnaire"
              @update:answer="updateAnswer"
              :clickedOnSlider="clickedOnSlider"
              :answer="answer"
              @selected-question="checkOnBoardingPage"
              @click-slider="clickToSlider"
              @cancel-next-question="cancelNextQuestion"
              :read-only="finishTyping"
              :is-next-question="isNextQuestion"
            />
          </div>
        </div>
      </Content>
    </div>
    <LoaderGetResult
      :image="congratulations"
      :title="'CONGRATULATIONS'"
      :subTitle="'Halfway there!'"
      :text-button="'TAP TO CONTINUE'"
      :time="30"
      @next-step="showResultPage"
      v-if="!completedResult && !isPersonalityTest()">
    </LoaderGetResult>
  </div>
</template>

<script>
import facets from '@constants/facets';
import { debounce } from 'lodash';
import LoaderGetResult from '@innerworks_ds/innerworks-storybook-main/src/stories/Loader/LoaderGetResult/LoaderGetResult.vue';
import OwnFeedbackModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/OwnFeedbackModal/OwnFeedbackModal.vue';
import { mapGetters } from 'vuex';
import QuestionSlider from '@components/Slider/QuestionSlider.vue';
import userBehave from '@constants/userBehave';
import congratulations from '@assets/congratulations.svg';
import Question from '@innerworks_ds/innerworks-storybook-main/src/stories/Questions/Question.vue';
import CheckPointScreen from '@innerworks_ds/innerworks-storybook-main/src/stories/CheckPointScreen/CheckPointScreen.vue';
import differentsServices from '@helpers/differentsServices';

export default {
  components: {
    CheckPointScreen,
    QuestionSlider,
    OwnFeedbackModal,
    Question,
    LoaderGetResult,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    startFromQuestion: {
      type: String,
    },
  },
  data() {
    return {
      congratulations,
      onBoardingQuestionnaire: false,
      currentQuestion: null,
      currentIndex: 0,
      answer: 0,
      formData: {},
      completedResult: true,
      clickedOnSlider: false,
      debouncedHandler: null,
      isOpenOwnFeedBackErrorModal: false,
      errorMessage: '',
      finishTyping: true,
      showCheckPointPage: false,
      isNextQuestion: false,
      onBoardingQuestion: 'A marked score can’t be undone',
      imageCheckPointScreen: '',
      nameCheckPointScreen: '',
      textUnderImage: '',
      behaveQuestion: [],
      behaveQuestionDuringTyping: [],
      timeBetweenAnswers: null,
      typingQuestionTime: null,
      delayTimeOut: null,
      titleName: '',
      thanksU2Page: false,
      deplayForAnimation: false,
      getDeviceType: {},
      howQuestionBeforeCheckPointScreen: 15,
    };
  },
  watch: {
    questions: {
      handler(value) {
        if (value && value.length) {
          // eslint-disable-next-line
          for (const property in value) {
            if (value[property].qid === this.startFromQuestion) {
              this.currentIndex = +property;
              this.$store.dispatch('quiz/updateQuestionnaireData', {
                currentIndex: this.currentIndex,
                countOfQuestion: this.questions.length,
                showProgressBar: true,
                numberCheckPointScreen: Math.floor(this.countCheckpointScreens),
                questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
              });
            }
          }
          if (this.currentIndex === 0) {
            this.onBoardingQuestionnaire = true;
            this.$store.dispatch('quiz/updateQuestionnaireData', {
              currentIndex: this.currentIndex,
              countOfQuestion: this.questions.length,
              showProgressBar: false,
              numberCheckPointScreen: 0,
              questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
            });
          }
          this.currentQuestion = value[this.currentIndex];
          this.typingQuestionTime = this.getMilliseconds();
        }
      },
      immediate: true,
    },
    answer: {
      handler(value) {
        this.$store.dispatch('quiz/saveLastAnswer', value);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getInvitationData: 'invitation/getInvitationData',
      facets: 'quiz/getFacets',
      getLastAnswer: 'quiz/getLastAnswer',
    }),
    getTickSize() {
      return Math.abs(Math.round(this.answer) * 5) + 60;
    },
    isAuth() {
      return this.getProfile.token;
    },
    countCheckpointScreens() {
      return this.currentIndex / this.howQuestionBeforeCheckPointScreen;
    },
    listU2CheckPointData() {
      if (Object.keys(this.getInvitationData).length !== 0) {
        const name = this.getInvitationData.name ? this.getInvitationData.name.toUpperCase() : '';
        return [
          {
            titleName: `${name} IS`,
            nameCheckPointScreen: 'one step closer',
            textUnderImage: 'TO LANDING THE DREAM JOB!',
          },
          {
            titleName: `${name} IS GETTING AHEAD`,
            nameCheckPointScreen: 'of the job queue',
            textUnderImage: 'THANKS TO YOUR SUPPORT!',
          },
          {
            titleName: `${name}’S EMPLOYABILITY`,
            nameCheckPointScreen: 'is increasing by 88%',
            textUnderImage: '#SUPERPOWERS',
          },
          {
            titleName: `${name} WILL STAND OUT`,
            nameCheckPointScreen: 'in their job hunt',
            textUnderImage: '#BLESSED',
          },
          {
            titleName: `${name} IS MOVING TO`,
            nameCheckPointScreen: 'the top of the pile',
            textUnderImage: '#BLESSED',
          },
          {
            titleName: `${name} IS CHANGING`,
            nameCheckPointScreen: 'employers views',
            textUnderImage: '#REVOLUTIONARY',
          },
          {
            titleName: `IF IT WASN’T FOR YOU, ${name}`,
            nameCheckPointScreen: '... would be just another CV',
            textUnderImage: '#HERO',
          },
        ];
      }
      return [];
    },
  },
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
    this.behaveUserDuringTypeTheQuestion();
    this.getUserBrowserData();
  },
  methods: {
    getUserBrowserData() {
      const clientData = differentsServices.getClientData();
      this.getDeviceType = {
        deviceType: clientData && clientData.deviceType ? clientData.deviceType : 'computer',
        browser: clientData.browser,
        browserVersion: clientData.browserVersion,
        operationSystem: clientData.operationSystem,
      };
    },
    behaveUserDuringTypeTheQuestion() {
      // eslint-disable-next-line func-names
      document.querySelector('#app').onclick = (event) => {
        if (this.finishTyping) {
          // eslint-disable-next-line no-param-reassign
          event = event || window.event;
          const currentSeconds = this.getMilliseconds();
          const calculationResult = ((currentSeconds - this.typingQuestionTime) / 1000);

          this.behaveQuestionDuringTyping.push({
            timeOfClick: calculationResult,
            positionOfClickX: event.pageX,
            positionOfClickY: event.pageY,
          });
        }
      };
    },
    redirectToLogin() {
      this.$router.push({
        name: 'main',
      });
      this.$store.dispatch('invitation/setInvitationData', {});
    },
    afterEnter() {
      const active = document.querySelector('.animation-checkpoint-page');
      if (active && this.deplayForAnimation) {
        active.classList.add('circle-without-delay-leave-active');
      } else {
        active.classList.add('circle-with-delay-leave-active');
      }
    },
    nextQuestionEvent(event) {
      this.deplayForAnimation = event;
      this.showCheckPointPage = false;
      const position = document.querySelector('.v-slider__thumb-label div').getBoundingClientRect();
      document.querySelector('.questionnaire-page').style.setProperty('--h', `${position.y + window.scrollY + (position.height / 2)}px`);
      document.querySelector('.questionnaire-page').style.setProperty('--w', '50%');
      this.currentQuestion = ' ';
      this.answer = 0;
    },
    afterLeave() {
      this.isNextQuestion = false;
      this.currentQuestion = this.questions[this.currentIndex];
      this.clickedOnSlider = false;
      this.finishTyping = true;
    },
    showResultPage() {
      this.$router.push('result');
    },
    completedTyping(value) {
      this.finishTyping = value;
      if (value === false) {
        this.timeBetweenAnswersMilliseconds = this.getMilliseconds();
      }
    },
    closeWarningModalModal(value) {
      this.isOpenOwnFeedBackErrorModal = value;
    },
    updateAnswer(answer) {
      this.clickedOnSlider = true;
      this.answer = answer;
      if (this.clickedOnSlider && !this.onBoardingQuestionnaire) {
        this.updateUserAnswerBehave(answer);
      }
    },
    async saveAnswerByPersonalityTest() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }

      let requestParam = '?option=';
      if (this.$route.query.option) {
        requestParam += this.$route.query.option;
      } else {
        requestParam = '';
      }

      return this.$store.dispatch('invitation/setPersonalityTest',
        {
          formData: this.formData,
          u1Email: this.getProfile.email,
          notificationCrowdEmails: this.getProfile.latestNews,
          id: this.getInvitationData.userId,
          uniqueId: localStorage.getItem('uniqueId'),
          requestParam,
        }).then(() => {
        this.$router.push({
          name: 'success-invitation',
        });
      }).catch((e) => {
        this.isOpenOwnFeedBackErrorModal = true;
        this.errorMessage = e.response.data.message;
        this.currentQuestion = true;
        this.completedResult = false;
      });
    },
    clickToSlider() {
      this.clickedOnSlider = true;
    },
    getMilliseconds() {
      return new Date().getTime();
    },
    cancelNextQuestion() {
      if (this.debouncedHandler) {
        this.debouncedHandler.cancel();
      }
    },
    checkOnBoardingPage(answer) {
      if (this.onBoardingQuestionnaire) {
        this.showOnBoardingPage();
      } else {
        this.onSelectedQuestion(answer);
      }
    },
    onSelectedQuestion(answer) {
      this.updateUserAnswerBehave(answer);
      this.debouncedHandler = debounce(async () => {
        this.isNextQuestion = true;
        this.finishTyping = true;
        this.formData[this.questions[this.currentIndex].qid] = answer;

        this.generateAnonymous();
        let u2Id = localStorage.getItem('uniqueId');

        if (Object.keys(this.getInvitationData).length !== 0) {
          u2Id = this.getInvitationData.userId;
        } else if (this.getProfile.token) {
          u2Id = this.getProfile.id;
        }

        this.userBehaveSentEvent();

        this.$api.questionnaire.savePartialAnswers({
          [this.questions[this.currentIndex].qid]: answer,
        }, {
          u2Id,
          self: Object.keys(this.getInvitationData).length === 0,
        }, localStorage.getItem('uniqueId')).then(() => {
          this.currentIndex += 1;
          this.$store.dispatch('quiz/updateQuestionnaireData', {
            currentIndex: this.currentIndex,
            countOfQuestion: this.questions.length,
            showProgressBar: true,
            numberCheckPointScreen: Math.floor(this.countCheckpointScreens),
            questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
          });

          setTimeout(async () => {
            this.isNextQuestion = false;
            this.nextQuestion();

            // partial result page
            if ((this.currentIndex) % this.howQuestionBeforeCheckPointScreen === 0
              && this.currentIndex !== 0) {
              const position = document.querySelector('.v-slider__thumb-label div').getBoundingClientRect();
              const position2 = document.querySelector('.question-content').getBoundingClientRect();
              document.querySelector('.questionnaire-page').style.setProperty('--h', `${position.y + window.scrollY + (position.height / 2)}px`);
              document.querySelector('.questionnaire-page').style.setProperty('--w', `${position.left - position2.left + (position.width / 2)}px`);

              await this.checkPointScreenLogic();
            }

            if (this.currentIndex === 0) {
              await this.$store.dispatch('showComponent/turnOffHeader');
            }
          }, 500);
        });
      }, 300);

      return this.debouncedHandler();
    },
    updateUserAnswerBehave(answer) {
      const currentSeconds = this.getMilliseconds();
      const calculationResult = ((currentSeconds - this.timeBetweenAnswersMilliseconds) / 1000);
      const lengthBehaveQuestion = this.behaveQuestion.length - 1;

      let changeTime = calculationResult;
      if (this.behaveQuestion.length === 0 && calculationResult <= 0.2) {
        changeTime = 0;
      }

      if (this.behaveQuestion.length !== 0
        && this.behaveQuestion[lengthBehaveQuestion].answer === answer) {
        this.behaveQuestion[lengthBehaveQuestion].timeQuestions += (changeTime / 1000.0);
      } else {
        this.behaveQuestion.push({
          answer,
          timeQuestions: changeTime,
        });
      }

      this.timeBetweenAnswersMilliseconds = this.getMilliseconds();
    },
    async checkPointScreenLogic() {
      let u2Id = localStorage.getItem('uniqueId');

      if (Object.keys(this.getInvitationData).length !== 0) {
        u2Id = this.getInvitationData.userId;
      } else if (this.getProfile.token) {
        u2Id = this.getProfile.id;
      }

      const requestData = {
        uniqueId: localStorage.getItem('uniqueId'),
        u2Id,
        self: Object.keys(this.getInvitationData).length === 0,
      };

      await this.$store.dispatch('quiz/saveFacets', requestData);
      if (Object.keys(this.getInvitationData).length !== 0) {
        await this.setDataCheckpointScreenU2Quiz();
      } else {
        await this.setDataCheckpointScreenU1Quiz();
      }
    },
    async setDataCheckpointScreenU2Quiz() {
      if (this.facets.count > 7) {
        await this.$store.dispatch('quiz/updateFacets', { count: 1 });
      }
      this.imageCheckPointScreen = `General${this.facets.count}U2Image`;
      this.nameCheckPointScreen = this.listU2CheckPointData[this.facets.count - 1]
        .nameCheckPointScreen;
      this.titleName = this.listU2CheckPointData[this.facets.count - 1].titleName;
      this.textUnderImage = this.listU2CheckPointData[this.facets.count - 1].textUnderImage;
      this.showCheckPointPage = true;
    },
    async setDataCheckpointScreenU1Quiz() {
      const facetsObject = facets.find(x => this.facets.name.toLowerCase() === x.key.toLowerCase());

      if (facetsObject && facetsObject.images[this.facets.count - 1] == null) {
        if (this.facets.count > 5 || !this.facets.general) {
          await this.$store.dispatch('quiz/updateFacets', { count: 1 });
        }

        this.imageCheckPointScreen = `General${this.facets.count}Image`;
        this.nameCheckPointScreen = 'True Strengths';
        this.titleName = 'WE ARE SEEING SOME';
        this.setTitleCheckPointScreen(true);
        await this.$store.dispatch('quiz/updateFacets', { count: this.facets.count, general: true });
      } else if (!this.facets.general && facetsObject
        && facetsObject.images[this.facets.count - 1] != null) {
        this.imageCheckPointScreen = facetsObject.images[this.facets.count - 1];
        this.nameCheckPointScreen = facetsObject.title[this.facets.count - 1];
        this.setTitleCheckPointScreen(false);
      } else {
        if (this.facets.count > 5 || !this.facets.general) {
          await this.$store.dispatch('quiz/updateFacets', { count: 1 });
        }

        this.imageCheckPointScreen = `General${this.facets.count}Image`;
        this.nameCheckPointScreen = 'True Strengths';
        this.titleName = 'WE ARE SEEING SOME';
        this.setTitleCheckPointScreen(true);
        await this.$store.dispatch('quiz/updateFacets', { count: this.facets.count, general: true });
      }

      this.showCheckPointPage = true;
    },
    setTitleCheckPointScreen(isGeneralScreen) {
      if (isGeneralScreen) {
        this.titleName = 'WE ARE SEEING SOME';
      } else {
        this.titleName = 'YOU SEEM';
      }
    },
    showThanksPage() {
      this.debouncedHandler = debounce(() => {
        this.onBoardingQuestion = `Let’s find ${this.getInvitationData.name}'s strengths!`;
        this.isNextQuestion = true;
        this.finishTyping = true;
        setTimeout(() => {
          this.thanksU2Page = true;
          this.isNextQuestion = false;
          this.answer = 0;
          this.clickedOnSlider = false;
        }, 400);
      }, 300);

      return this.debouncedHandler();
    },
    showOnBoardingPage() {
      let timer = 300;
      if (Object.keys(this.getInvitationData).length !== 0) {
        timer = 4000;
        this.showThanksPage();
      }
      this.debouncedHandler = debounce(() => {
        this.isNextQuestion = true;
        this.finishTyping = true;
        if (Object.keys(this.getInvitationData).length === 0) {
          this.$api.userBehaveSite.saveSiteEvent(userBehave.letsGetsStarted);
        } else {
          this.$api.userBehaveSite.saveSiteEvent(userBehave.letsGetsStartedCrowd);
        }
        setTimeout(() => {
          this.isNextQuestion = false;
          this.answer = 0;
          this.clickedOnSlider = false;

          setTimeout(() => {
            this.onBoardingQuestionnaire = false;
            setTimeout(() => {
              this.thanksU2Page = false;
            }, 300);
            this.$store.dispatch('quiz/updateQuestionnaireData', {
              currentIndex: this.currentIndex,
              countOfQuestion: this.questions.length,
              showProgressBar: true,
              numberCheckPointScreen: Math.floor(this.countCheckpointScreens),
              questionBeforeCheckpointScreen: this.howQuestionBeforeCheckPointScreen,
            });
          }, 400);
        }, 500);
      }, timer);

      return this.debouncedHandler();
    },
    async nextQuestion() {
      if (this.currentIndex >= this.questions.length) {
        this.answer = 0;
        this.currentIndex = 0;
        this.currentQuestion = null;
        this.loading = true;
        await this.saveAnswer();
        this.completedResult = false;
        this.loading = false;
      } else {
        if ((this.currentIndex) % this.howQuestionBeforeCheckPointScreen !== 0) {
          this.answer = 0;
          this.currentQuestion = this.questions[this.currentIndex];
          this.typingQuestionTime = this.getMilliseconds();
        }
        this.clickedOnSlider = false;
      }
    },
    userBehaveSentEvent() {
      const event = this.isPersonalityTest() ? userBehave.answerToQuestionCrowd
        : userBehave.answerToQuestion;

      const eventDuringTypingData = {
        widthOfScreen: document.documentElement.clientWidth,
        heightOfScreen: document.documentElement.clientHeight,
        ...this.getDeviceType,
      };

      this.$api.userBehaveSite.saveUserEventDuringTypingQuestions(event + this.currentIndex,
        eventDuringTypingData, this.behaveQuestionDuringTyping).then(() => {
        this.behaveQuestionDuringTyping = [];
      });


      this.$api.userBehaveSite.saveSiteEvent(event + this.currentIndex, this.behaveQuestion)
        .then(() => {
          this.behaveQuestion = [];
        });
    },
    isPersonalityTest() {
      return this.getInvitationData.questionnaireId === process.env.QUESTIONNAIRE_ID;
    },
    async saveAnswer() {
      this.completedColleaguesTest();

      if (this.isPersonalityTest()) {
        return this.saveAnswerByPersonalityTest();
      }

      if (!this.isAuth && !this.isPersonalityTest()) {
        this.generateAnonymous();

        return this.$api.questionnaire.saveAnswerFreeVersionNew(this.formData, localStorage.getItem('uniqueId'))
          .then(() => {
            const profile = {
              ...this.getProfile,
              completedQuestionnaires: [process.env.QUESTIONNAIRE_ID],
            };
            this.$store.dispatch('auth/updateProfile', profile).then(() => {
            });
          }).catch(() => {
            const profile = {
              ...this.getProfile,
              completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
                process.env.QUESTIONNAIRE_ID],
            };

            this.$store.dispatch('auth/updateProfile', profile);
          });
      }

      return this.$api.questionnaire.saveAnswer(this.formData)
        .then(() => {
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {

          });
        }).catch(() => {
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {
            this.$router.push('result');
          });
        });
    },
    completedColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Complete test', // eslint-disable-line
        'value': 1,                                                             // eslint-disable-line
      });
    },
    generateAnonymous() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-center {
  height: 125px;
}
.animation-checkpoint-page {
  position: absolute;
  display: block;
  z-index: 100;
  top: 0;
}

.circle-enter-active {
  display: block;
  animation: 3000ms circle forwards;
}

.circle-with-delay-leave-active {
  display: block;
  animation: 3000ms circle reverse forwards;
  animation-delay: 900ms;
}

.circle-without-delay-leave-active {
  display: block;
  animation: 2000ms circle reverse forwards;
}

@keyframes circle {
  from {
    clip-path: circle(0% at var(--w) var(--h));
  }
  to {
    clip-path: circle(110%);
  }
}

.question-content {
  box-sizing: border-box;
  overflow-y: hidden;
  z-index: 100;
}

.container {
  background: transparent;
}

::v-deep.question {
  .container {
    background: transparent;
  }

  @media (max-width: 330px) {
    .question__select-result {
      padding-top: 18px;

      .v-slider__thumb-label {
        bottom: 100px !important;
      }

      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 22px !important;
    }
  }
  @media (max-width: 380px) {
    .current-question {
      margin-top: 50px;
    }
    .question__select-result {
      padding-top: 18px;

      .v-slider__thumb-label {
        bottom: 100px !important;
      }

      .info-use {
        margin-top: 0 !important;
      }
    }
    .question__content {
      font-size: 28px;
    }
  }
  @media (max-height: 600px) {
    .question__select-result {
      .info-use {
        margin-top: -10px !important;
      }
    }
  }

  .question__select-result {
    .info-use {
      font-family: $newDefaultFont;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #222222;
    }

    .info-text {
      opacity: 0.3;
      margin-top: 21px;
    }
  }
}
</style>
