<template>
  <div class="question questionnaire-inner" :style="{background: getLastAnswer.color}">
    <div class="header-initial">
      <SurveyStep
        :questions="questions"
        :startFromQuestion="startFromQuestion"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import checkbox from '../assets/checkbox_fill.svg';
import SurveyStep from '../modules/Step/SurveyStep.vue';

export default {
  name: 'Questions',
  data: () => ({
    checkbox,
    questions: null,
    startFromQuestion: null,
    currentStep: 1,
    allStepCount: 1,
    selectedAnswer: null,
    formData: {},
    show: {
      questions: true,
      attentions: false,
    },
    userNameForCrowdSurvey: '',
    redirectLink: 'invintation-report',
    loading: false,
    showStartCrowdPage: false,
  }),
  components: {
    SurveyStep,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      startedPage: 'showComponent/startedPage',
      crowdSurveyPage: 'showComponent/crowdSurveyStep',
      backgroundColor: 'showComponent/backgroundColor',
      getInvitationData: 'invitation/getInvitationData',
      getLastAnswer: 'quiz/getLastAnswer',
    }),
    getDataByStep() {
      if (!this.questions) return null;

      if (this.currentStep === 1) {
        this.startColleaguesTest();
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.questions[this.currentStep - 1].text = this.questions[this.currentStep - 1].text
        .replace(/{{/, '<span class="highlighting-name">').replace(/}}/, '</span>');
      return this.questions[this.currentStep - 1];
    },
    isPersonalityTest() {
      return this.getInvitationData.questionnaireId === process.env.QUESTIONNAIRE_ID;
    },
    isAuth() {
      return this.getProfile.token;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    completedColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Complete test', // eslint-disable-line
        'value': 1,                                                             // eslint-disable-line
      });
    },
    startColleaguesTest() {
      this.$gtag.event('Click test', {
        [window.location.pathname.split('/').pop()]: 'Start test', // eslint-disable-line
        'value': 1,                                                          // eslint-disable-line
      });
    },
    fetchData() {
      if (this.isPersonalityTest) {
        return this.fetchInvitationQuestionnaire(this.getInvitationData.userId);
      }
      return this.fetchQuestions();
    },
    fetchQuestions() {
      this.$api.questionnaire.fetchQuestionnaireNew(localStorage.getItem('uniqueId'))
        .then((data) => {
          this.setResponseData(data);
        });
    },
    fetchInvitationQuestionnaire(id) {
      let u1Id = localStorage.getItem('uniqueId');

      if (this.getProfile.token) {
        u1Id = this.getProfile.id;
      }

      this.$api.questionnaire.fetchInvitationQuestionnaire(id, u1Id)
        .then((data) => {
          this.setResponseData(data);
        }).catch(() => {
          this.$router.push({ name: 'main' });
        });
    },
    async setResponseData(data) {
      this.questions = data.questionList2;
      this.userNameForCrowdSurvey = data.userName;
      if (this.isPersonalityTest) {
        await this.$store.dispatch('invitation/updateInvitationData', { name: data.userName });
      }
      this.startFromQuestion = data.startFromQuestionId;
      this.setLengthStep(data.questionList2);
    },
    setLengthStep(data) {
      this.allStepCount = data.length;
    },
    selectedAnswers(question) {
      this.selectedAnswer = question;
    },
    setStep(step) {
      this.currentStep = step;
    },
    setAnswer(questionId) {
      this.formData[this.getDataByStep.qid] = questionId;
    },
    async saveAnswerByPersonalityTest() {
      if (!localStorage.getItem('uniqueId')) {
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }

      let requestParam = '?option=';
      if (this.$route.query.option) {
        requestParam += this.$route.query.option;
      } else {
        requestParam = '';
      }

      return this.$store.dispatch('invitation/setPersonalityTest',
        {
          formData: this.formData,
          id: this.$route.params.id,
          uniqueId: localStorage.getItem('uniqueId'),
          requestParam,
        }).then(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }

        return this.toggleShowContent();
      }).catch(() => {
        if (this.isAuth) {
          return this.$router.push({ name: 'questionnaire-management' });
        }
        return this.$router.push({
          name: 'main',
        });
      });
    },
    setRedirectAuth() {
      this.$store.dispatch('auth/setRedirectAuth', 'questionnaire-management');
    },
    toggleShowContent() {
      this.show.questions = !this.show.questions;
      this.show.attentions = !this.show.attentions;
    },
    async saveAnswer() {
      this.completedColleaguesTest();
      if (this.isPersonalityTest) {
        return this.saveAnswerByPersonalityTest();
      }

      if (!this.isAuth) {
        if (!localStorage.getItem('uniqueId')) {
          localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
            * Math.floor(Math.random() * Date.now()) * Math.random())}`);
        }

        return this.$api.questionnaire.saveAnswerFreeVersionNew(this.formData, localStorage.getItem('uniqueId'))
          .then(() => {
            this.$router.push({ name: '/questionnaire2' });
          }).catch(() => {
            this.$router.push({ name: '/questionnaire2' });
          });
      }


      return this.$api.questionnaire.saveAnswer(this.formData)
        .then(() => {
          const profile = {
            ...this.getProfile,
            completedQuestionnaires: [...this.getProfile.completedQuestionnaires,
              process.env.QUESTIONNAIRE_ID],
          };

          this.$store.dispatch('auth/updateProfile', profile).then(() => {
            this.$router.replace(this.getRedirectAuth);
          });
          this.$router.push({ name: 'questionnaire2' });
        });
    },
    // eslint-disable-next-line func-names
    nextStep: debounce(async function () {
      const nextStep = this.currentStep + 1;

      if (!this.selectedAnswer) return;

      this.setAnswer(this.selectedAnswer);
      this.selectedAnswer = null;

      if (nextStep <= this.allStepCount) {
        this.setStep(this.currentStep + 1);
      } else {
        this.loading = true;
        await this.saveAnswer();
        this.loading = false;
      }
    }, 700),
    selectAndNextStep(answer) {
      this.selectedAnswers(answer);
      this.nextStep();
    },
  },
};
</script>

<style lang="scss" scoped>
  .header-initial {
    margin: -2px 0 -2px 0;
    max-width: 480px !important;
    align-self: center;
    height: calc(100% - 72px);
  }
  .questionnaire-inner {
    background-color: rgba(120, 17, 201, 0.05);
    height: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.5s;
  }
</style>
