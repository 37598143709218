export default [
  {
    key: 'agreeable',
    title: ['Agreeable'],
    images: ['Agreeable'],
  },
  {
    key: 'conscientious',
    title: ['Conscientious'],
    images: ['Conscientious'],
  },
  {
    key: 'disciplined',
    title: ['Disciplined'],
    images: ['Disciplined'],
  },
  {
    key: 'disciplined',
    title: ['Disciplined'],
    images: ['Disciplined'],
  },
  {
    key: 'efficient',
    title: ['Efficient'],
    images: ['Efficient'],
  },
  {
    key: 'emotionality',
    title: ['Balanced'],
    images: ['Emotionality'],
  },
  {
    key: 'extraverted',
    title: ['Outgoing'],
    images: ['Extraverted'],
  },
  {
    key: 'imagination',
    title: ['Imaginative'],
    images: ['Imagination'],
  },
  {
    key: 'intellect',
    title: ['Intellectual', 'Intellectual'],
    images: ['Intellect1', 'Intellect2', 'Intellect3'],
  },
  {
    key: 'liberalism',
    title: ['Open-minded'],
    images: ['Liberalism'],
  },
  {
    key: 'adventurousness',
    title: ['Adventurous', 'Adventurous'],
    images: ['Adventurousness1', 'Adventurousness2'],
  },
  {
    key: 'artistic_interests',
    title: ['Interested in art', 'Interested in art'],
    images: ['ArtisticInterests1', 'ArtisticInterests2'],
  },
  {
    key: 'activity_level',
    title: ['Active'],
    images: ['ActivityLevel'],
  },
  {
    key: 'cautiousness',
    title: ['Cautious', 'Cautious'],
    images: ['Cautiousness1', 'Cautiousness2'],
  },
  {
    key: 'cheerfulness',
    title: ['Cheerful'],
    images: ['Cheerfulness'],
  },
  {
    key: 'excitement_seeking',
    title: ['Thrill-seeking', 'Thrill-seeking'],
    images: ['ExcitementSeeking1', 'ExcitementSeeking2'],
  },
  {
    key: 'friendliness',
    title: ['Sociable'],
    images: ['Friendliness'],
  },
  {
    key: 'gregariousness',
    title: ['Communicative'],
    images: ['Gregariousness'],
  },
  {
    key: 'achievement_striving',
    title: ['Hard-working', 'Hard-working'],
    images: ['AchievementStriving1', 'AchievementStriving2'],
  },
  {
    key: 'assertiveness',
    title: ['Assertive'],
    images: ['Assertiveness'],
  },
  {
    key: 'dutifulness',
    title: ['Conscientious', 'Conscientious'],
    images: ['Dutifulness1', 'Dutifulness2'],
  },
  {
    key: 'orderliness',
    title: ['Organized'],
    images: ['Orderliness'],
  },
  {
    key: 'self_discipline',
    title: ['Disciplined'],
    images: ['SelfDiscipline'],
  },
  {
    key: 'self_efficacy',
    title: ['Efficient'],
    images: ['SelfEfficacy'],
  },
  {
    key: 'altruism',
    title: ['Altruistic'],
    images: ['AltruisticInterests'],
  },
  {
    key: 'cooperation',
    title: ['Collaborative'],
    images: ['Cooperation'],
  },
  {
    key: 'modesty',
    title: ['Modest'],
    images: ['Modesty'],
  },
  {
    key: 'morality',
    title: ['Moral'],
    images: ['Morality'],
  },
  {
    key: 'sympathy',
    title: ['Sympathetic', 'Open-minded', 'Efficient'],
    images: ['Sympathy'],
  },
  {
    key: 'trust',
    title: ['Trustworthy'],
    images: ['Trust'],
  },
];
