<template>
  <div>
    <NewQuestion
      :questions="questions"
      :start-from-question="startFromQuestion"
    />
  </div>
</template>
<script>
import NewQuestion from '../Questions/index.vue';

export default {
  name: 'Page1',
  components: {
    NewQuestion,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    startFromQuestion: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>

</style>
